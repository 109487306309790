import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { PoolDataResponse, PoolFields } from 'views/V3Info/data/pool/poolData'
import { ChartDayData, Transaction, TransactionType } from 'views/V3Info/types'

import { getBlockFromTimestamp } from './blocks/queries'
import { BLACKLIST } from './constants/blacklist'

import { client as clientV2 } from './v2/client'
import {
  DAY_DATA as DAY_DATA_V2,
  LAST_TRANSACTIONS,
  PAIRS_VOLUME_QUERY as PAIRS_VOLUME_QUERY_V2,
  PROTOCOL_DATA_BY_BLOCK,
  PROTOCOL_DATA_LAST,
  TOKEN_BY_ADDRESS as TOKEN_BY_ADDRESS_V2,
  TOP_PAIRS as TOP_PAIRS_V2,
  TOP_POOLS_BY_BLOCK,
  TOP_POOLS_LAST,
  TOP_TOKENS_BY_BLOCK,
  TOP_TOKENS_LAST,
} from './v2/queries'
import {
  PairsVolumeQuery as PairsVolumeQueryV2,
  PairsVolumeQueryVariables as PairsVolumeQueryVariablesV2,
  SectaDayDataQuery as SectaDayDataQueryV2,
  SectaDayDataQueryVariables as SectaDayDataQueryVariablesV2,
  SectaLastTranscationsQuery,
  SectaLastTranscationsQueryVariables,
  SectaProtocolDataByBlockQuery,
  SectaProtocolDataByBlockQueryVariables,
  SectaProtocolDataLastQuery,
  SectaProtocolDataLastQueryVariables,
  SectaTopPoolsByBlockQuery,
  SectaTopPoolsByBlockQueryVariables,
  SectaTopPoolsLastQuery,
  SectaTopPoolsLastQueryVariables,
  SectaTopTokensByBlockQuery,
  SectaTopTokensByBlockQueryVariables,
  SectaTopTokensLastQuery,
  SectaTopTokensLastQueryVariables,
  TokenQuery as TokenQueryV2,
  TokenQueryVariables as TokenQueryVariablesV2,
  TopPairsQuery as TopPairsQueryV2,
  TopPairsQueryVariables as TopPairsQueryVariablesV2,
} from './v2/subgraph-v2'

import { client as clientV3 } from './v3/client'
import {
  DAY_DATA as DAY_DATA_V3,
  LAST_TRANSACTIONS as LAST_TRANSACTIONS_V3,
  PROTOCOL_DATA_BY_BLOCK as PROTOCOL_DATA_BY_BLOCK_V3,
  PROTOCOL_DATA_LAST as PROTOCOL_DATA_LAST_V3,
  TOP_POOLS_BY_BLOCK as TOP_POOLS_BY_BLOCK_V3,
  TOP_POOLS_LAST as TOP_POOLS_LAST_V3,
  TOP_TOKENS_BY_BLOCK as TOP_TOKENS_BY_BLOCK_V3,
  TOP_TOKENS_LAST as TOP_TOKENS_LAST_V3,
} from './v3/queries'
import {
  SectaDayDataQuery as SectaDayDataQueryV3,
  SectaDayDataQueryVariables as SectaDayDataQueryVariablesV3,
  SectaLastTranscationsQuery as SectaLastTranscationsQueryV3,
  SectaLastTranscationsQueryVariables as SectaLastTranscationsQueryVariablesV3,
  SectaProtocolDataByBlockQuery as SectaProtocolDataByBlockQueryV3,
  SectaProtocolDataByBlockQueryVariables as SectaProtocolDataByBlockQueryVariablesV3,
  SectaProtocolDataLastQuery as SectaProtocolDataLastQueryV3,
  SectaProtocolDataLastQueryVariables as SectaProtocolDataLastQueryVariablesV3,
  SectaTopPoolsByBlockQuery as SectaTopPoolsByBlockQueryV3,
  SectaTopPoolsByBlockQueryVariables as SectaTopPoolsByBlockQueryVariablesV3,
  SectaTopPoolsLastQuery as SectaTopPoolsLastQueryV3,
  SectaTopPoolsLastQueryVariables as SectaTopPoolsLastQueryVariablesV3,
  SectaTopTokensByBlockQuery as SectaTopTokensByBlockQueryV3,
  SectaTopTokensByBlockQueryVariables as SectaTopTokensByBlockQueryVariablesV3,
  SectaTopTokensLastQuery as SectaTopTokensLastQueryV3,
  SectaTopTokensLastQueryVariables as SectaTopTokensLastQueryVariablesV3,
} from './v3/subgraph-v3'

const TOP_PAIR_LIMIT = 1000
export type Token = TokenQueryV2['token']
export type Pair = TopPairsQueryV2['pairs'][number]

export interface MappedDetailedPair extends Pair {
  price: string
  previous24hVolumeToken0: string
  previous24hVolumeToken1: string
}

export async function getTokenByAddress(address: string): Promise<Token> {
  const {
    data: { token },
    errors: tokenErrors,
  } = await clientV2.query<TokenQueryV2, TokenQueryVariablesV2>({
    query: TOKEN_BY_ADDRESS_V2,
    variables: {
      id: address,
    },
    fetchPolicy: 'cache-first',
  })

  if (tokenErrors && tokenErrors.length > 0) {
    throw new Error('Failed to fetch token from subgraph')
  }

  return token
}

export async function getTopPairs(): Promise<MappedDetailedPair[]> {
  const epochSecond = Math.round(new Date().getTime() / 1000)
  const firstBlock = await getBlockFromTimestamp(epochSecond - 86400)

  if (!firstBlock) {
    throw new Error('Failed to fetch blocks from the subgraph')
  }

  const {
    data: { pairs },
    errors: topPairsErrors,
  } = await clientV2.query<TopPairsQueryV2, TopPairsQueryVariablesV2>({
    query: TOP_PAIRS_V2,
    variables: {
      limit: TOP_PAIR_LIMIT,
      excludeTokenIds: BLACKLIST,
    },
    fetchPolicy: 'cache-first',
  })

  if (topPairsErrors && topPairsErrors.length > 0) {
    throw new Error('Failed to fetch pairs from the subgraph')
  }

  const {
    data: { pairVolumes },
    errors: yesterdayVolumeErrors,
  } = await clientV2.query<PairsVolumeQueryV2, PairsVolumeQueryVariablesV2>({
    query: PAIRS_VOLUME_QUERY_V2,
    variables: {
      limit: TOP_PAIR_LIMIT,
      pairIds: pairs.map((pair) => pair.id),
      blockNumber: +firstBlock,
    },
    fetchPolicy: 'cache-first',
  })

  if (yesterdayVolumeErrors && yesterdayVolumeErrors.length > 0) {
    throw new Error(`Failed to get volume info for 24h ago from the subgraph`)
  }

  const yesterdayVolumeIndex =
    pairVolumes?.reduce<{
      [pairId: string]: { volumeToken0: BigNumber; volumeToken1: BigNumber }
    }>((memo, pair) => {
      memo[pair.id] = {
        volumeToken0: new BigNumber(pair.volumeToken0),
        volumeToken1: new BigNumber(pair.volumeToken1),
      }
      return memo
    }, {}) ?? {}

  return (
    pairs?.map((pair): MappedDetailedPair => {
      const yesterday = yesterdayVolumeIndex[pair.id]

      return {
        ...pair,
        price:
          pair.reserve0 !== '0' && pair.reserve1 !== '0'
            ? new BigNumber(pair.reserve1).dividedBy(pair.reserve0).toString()
            : '0',
        previous24hVolumeToken0:
          pair.volumeToken0 && yesterday?.volumeToken0
            ? new BigNumber(pair.volumeToken0).minus(yesterday.volumeToken0).toString()
            : new BigNumber(pair.volumeToken0).toString(),
        previous24hVolumeToken1:
          pair.volumeToken1 && yesterday?.volumeToken1
            ? new BigNumber(pair.volumeToken1).minus(yesterday.volumeToken1).toString()
            : new BigNumber(pair.volumeToken1).toString(),
      }
    }) ?? []
  )
}

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)
const ONE_DAY_UNIX = 24 * 60 * 60

interface ChartResults {
  pancakeDayDatas: {
    date: number
    volumeUSD: string
    tvlUSD: string
    feesUSD: string
  }[]
}

export async function getDayData() {
  const startTimestamp = 1619170975
  const endTimestamp = dayjs.utc().unix()
  const skip = 0

  try {
    // Fetch V2 data
    const {
      data: { pancakeDayDatas: pancakeDayDatasV2 },
    } = await clientV2.query<SectaDayDataQueryV2, SectaDayDataQueryVariablesV2>({
      query: DAY_DATA_V2,
      variables: {
        startTime: startTimestamp,
        skip,
      },
    })

    const {
      data: { pancakeDayDatas: pancakeDayDatasV3 },
    } = await clientV3.query<SectaDayDataQueryV3, SectaDayDataQueryVariablesV3>({
      query: DAY_DATA_V3,
      variables: {
        startTime: startTimestamp,
        skip,
      },
    })

    if (pancakeDayDatasV2 && pancakeDayDatasV3) {
      const formattedV2 = pancakeDayDatasV2.reduce((accum: { [date: number]: ChartDayData }, dayData) => {
        const roundedDate = parseInt((dayData.date / ONE_DAY_UNIX).toFixed(0))
        // eslint-disable-next-line no-param-reassign
        accum[roundedDate] = {
          date: dayData.date,
          volumeUSD: parseFloat(dayData.dailyVolumeUSD),
          tvlUSD: parseFloat(dayData.totalLiquidityUSD),
          feesUSD: new BigNumber(dayData.dailyVolumeUSD).multipliedBy(0.0025).toNumber(),
        }
        return accum
      }, {})

      const formattedV3 = pancakeDayDatasV3.reduce((accum: { [date: number]: ChartDayData }, dayData) => {
        const roundedDate = parseInt((dayData.date / ONE_DAY_UNIX).toFixed(0))
        // eslint-disable-next-line no-param-reassign
        accum[roundedDate] = {
          date: dayData.date,
          volumeUSD: parseFloat(dayData.volumeUSD),
          tvlUSD: parseFloat(dayData.tvlUSD),
          feesUSD: parseFloat(dayData.feesUSD),
        }
        return accum
      }, {})

      const firstV2Entry = formattedV2[parseInt(Object.keys(formattedV2)[0])]
      const firstV3Entry = formattedV3[parseInt(Object.keys(formattedV3)[0])]

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = firstV2Entry?.date ?? startTimestamp
      let latestV2Tvl = firstV2Entry?.tvlUSD ?? 0
      let latestV3Tvl = firstV3Entry?.tvlUSD ?? 0
      while (timestamp < endTimestamp - ONE_DAY_UNIX) {
        const nextDay = timestamp + ONE_DAY_UNIX
        const currentDayIndex = parseInt((nextDay / ONE_DAY_UNIX).toFixed(0))
        if (
          !Object.keys(formattedV2).includes(currentDayIndex.toString()) &&
          Object.keys(formattedV3).includes(currentDayIndex.toString())
        ) {
          formattedV3[currentDayIndex] = {
            date: nextDay,
            volumeUSD: formattedV3[currentDayIndex]?.volumeUSD,
            tvlUSD: latestV2Tvl + formattedV3[currentDayIndex]?.tvlUSD,
            feesUSD: formattedV3[currentDayIndex]?.feesUSD,
          }
        } else if (
          !Object.keys(formattedV3).includes(currentDayIndex.toString()) &&
          Object.keys(formattedV2).includes(currentDayIndex.toString())
        ) {
          formattedV3[currentDayIndex] = {
            date: nextDay,
            volumeUSD: formattedV2[currentDayIndex]?.volumeUSD,
            tvlUSD: latestV3Tvl + formattedV2[currentDayIndex]?.tvlUSD,
            feesUSD: formattedV2[currentDayIndex]?.feesUSD,
          }
        } else if (
          !Object.keys(formattedV2).includes(currentDayIndex.toString()) &&
          !Object.keys(formattedV3).includes(currentDayIndex.toString())
        ) {
          formattedV3[currentDayIndex] = {
            date: nextDay,
            volumeUSD: 0,
            tvlUSD: latestV2Tvl + latestV3Tvl,
            feesUSD: 0,
          }
        } else {
          formattedV3[currentDayIndex] = {
            date: nextDay,
            volumeUSD: new BigNumber(formattedV2[currentDayIndex]?.volumeUSD || '0')
              .plus(formattedV3[currentDayIndex]?.volumeUSD || 0)
              .toNumber(),
            tvlUSD: (formattedV2[currentDayIndex]?.tvlUSD || 0) + (formattedV3[currentDayIndex]?.tvlUSD || 0),
            feesUSD: new BigNumber(formattedV2[currentDayIndex]?.feesUSD || '0')
              .plus(formattedV3[currentDayIndex]?.feesUSD || 0)
              .toNumber(),
          }
          latestV2Tvl = formattedV2[currentDayIndex]?.tvlUSD || 0
          latestV3Tvl = formattedV3[currentDayIndex]?.tvlUSD || 0
        }
        timestamp = nextDay
      }

      return {
        data: Object.values(formattedV3),
        error: false,
      }
    }
    return {
      data: undefined,
    }
  } catch (error) {
    return {
      data: undefined,
      error,
    }
  }
}

export async function getProtocolData(block?: number) {
  try {
    const v2 = !block
      ? await clientV2.query<SectaProtocolDataLastQuery, SectaProtocolDataLastQueryVariables>({
          query: PROTOCOL_DATA_LAST,
          variables: {},
        })
      : await clientV2.query<SectaProtocolDataByBlockQuery, SectaProtocolDataByBlockQueryVariables>({
          query: PROTOCOL_DATA_BY_BLOCK,
          variables: { block: { number: block } },
        })

    const v3 = !block
      ? await clientV3.query<SectaProtocolDataLastQueryV3, SectaProtocolDataLastQueryVariablesV3>({
          query: PROTOCOL_DATA_LAST_V3,
          variables: {},
        })
      : await clientV3.query<SectaProtocolDataByBlockQueryV3, SectaProtocolDataByBlockQueryVariablesV3>({
          query: PROTOCOL_DATA_BY_BLOCK_V3,
          variables: { block: { number: block } },
        })

    const formattedData: {
      factories: {
        txCount: string
        totalVolumeUSD: string
        totalFeesUSD: string
        totalValueLockedUSD: string
        totalProtocolFeesUSD: string
      }[]
    } = {
      factories: [
        {
          txCount: (
            parseInt(v2.data?.pancakeFactories[0].totalTransactions) + parseInt(v3.data?.factories[0].txCount)
          ).toString(),
          totalVolumeUSD: new BigNumber(v2.data?.pancakeFactories[0].totalVolumeUSD)
            .plus(v3.data?.factories[0].totalVolumeUSD)
            .toString(),
          totalFeesUSD: new BigNumber(v2.data?.pancakeFactories[0].totalVolumeUSD)
            .multipliedBy(0.0025)
            .plus(v3.data?.factories[0].totalFeesUSD)
            .toString(),
          totalValueLockedUSD: (
            parseFloat(v2.data?.pancakeFactories[0].totalLiquidityUSD) +
            parseFloat(v3.data?.factories[0].totalValueLockedUSD)
          ).toString(),
          totalProtocolFeesUSD: new BigNumber(v3.data?.factories[0].totalFeesUSD).toString(),
        },
      ],
    }
    return {
      data: formattedData,
      error: false,
    }
  } catch (error) {
    return {
      data: undefined,
      error,
    }
  }
}

interface TokenFields {
  id: string
  symbol: string
  name: string
  derivedETH: string
  volumeUSD: string
  volume: string
  feesUSD: string
  txCount: string
  totalValueLocked: string
  totalValueLockedUSD: string
  decimals: string
  onlyV2?: boolean
}

interface TokenDataResponse {
  tokens: TokenFields[]
  bundles: {
    ethPriceUSD: string
  }[]
}

export async function getTopTokens(block?: number) {
  try {
    const v2 = !block
      ? await clientV2.query<SectaTopTokensLastQuery, SectaTopTokensLastQueryVariables>({
          query: TOP_TOKENS_LAST,
        })
      : await clientV2.query<SectaTopTokensByBlockQuery, SectaTopTokensByBlockQueryVariables>({
          query: TOP_TOKENS_BY_BLOCK,
          variables: { block: { number: block } },
        })

    const v3 = !block
      ? await clientV3.query<SectaTopTokensLastQueryV3, SectaTopTokensLastQueryVariablesV3>({
          query: TOP_TOKENS_LAST_V3,
        })
      : await clientV3.query<SectaTopTokensByBlockQueryV3, SectaTopTokensByBlockQueryVariablesV3>({
          query: TOP_TOKENS_BY_BLOCK_V3,
          variables: { block: { number: block } },
        })

    const v2TokensMap: { [key: string]: TokenFields } = v2.data.tokens.reduce((acc, token) => {
      // eslint-disable-next-line no-param-reassign
      acc[token.id] = {
        id: token.id,
        symbol: token.symbol,
        name: token.name,
        derivedETH: token.derivedETH,
        volumeUSD: token.tradeVolumeUSD,
        volume: token.tradeVolume,
        feesUSD: new BigNumber(token.tradeVolumeUSD).multipliedBy(0.0025).toString(),
        txCount: token.totalTransactions,
        totalValueLocked: token.totalLiquidity,
        totalValueLockedUSD: new BigNumber(token.totalLiquidity).multipliedBy(token.derivedUSD).toString(),
        decimals: token.decimals,
        onlyV2: true,
      }
      return acc
    }, {} as { [key: string]: TokenFields })

    const v3TokensMap: { [key: string]: TokenFields } = v3.data.tokens.reduce((acc, token) => {
      // eslint-disable-next-line no-param-reassign
      acc[token.id] = token
      return acc
    }, {} as { [key: string]: TokenFields })

    const addresses = Array.from(new Set([...Object.keys(v2TokensMap), ...Object.keys(v3TokensMap)]))
    const total = addresses
      .map((addr) => {
        const ta = v2TokensMap[addr]
        const tb = v3TokensMap[addr]
        if (ta && tb) {
          return {
            id: ta.id,
            symbol: ta.symbol,
            name: ta.name,
            decimals: ta.decimals,
            derivedETH: tb.derivedETH,
            volumeUSD: BigNumber(ta.volumeUSD).plus(tb.volumeUSD).toString(),
            volume: BigNumber(ta.volume).plus(tb.volume).toString(),
            feesUSD: BigNumber(ta.feesUSD).plus(tb.feesUSD).toString(),
            txCount: BigNumber(ta.txCount).plus(tb.txCount).toString(),
            totalValueLocked: BigNumber(ta.totalValueLocked).plus(tb.totalValueLocked).toString(),
            totalValueLockedUSD: BigNumber(ta.totalValueLockedUSD).plus(tb.totalValueLockedUSD).toString(),
            onlyV2: false,
          }
        }
        if (v2TokensMap[addr]) {
          return v2TokensMap[addr]
        }
        if (v3TokensMap[addr]) {
          return v3TokensMap[addr]
        }
        return v2TokensMap[addr]
      })
      .sort((a, b) => {
        return parseFloat(b.totalValueLockedUSD) - parseFloat(a.totalValueLockedUSD)
      })

    const bundles = [
      {
        ethPriceUSD: v3.data?.bundles[0].ethPriceUSD,
      },
    ]

    const finalData: TokenDataResponse = {
      tokens: total,
      bundles,
    }

    return finalData
  } catch (error) {
    return undefined
  }
}

export async function getTopPools(block?: number) {
  try {
    const v2 = !block
      ? await clientV2.query<SectaTopPoolsLastQuery, SectaTopPoolsLastQueryVariables>({ query: TOP_POOLS_LAST })
      : await clientV2.query<SectaTopPoolsByBlockQuery, SectaTopPoolsByBlockQueryVariables>({
          query: TOP_POOLS_BY_BLOCK,
          variables: { block: { number: block } },
        })

    const v2Formatted: PoolFields[] = v2.data?.pairs.map((pool) => {
      return {
        id: pool.id,
        feeTier: '2500',
        liquidity: pool.reserve0,
        sqrtPrice: 'v2',
        tick: 'v2',
        token0: {
          id: pool.token0.id,
          name: pool.token0.name,
          symbol: pool.token0.symbol,
          address: pool.token0.id,
          decimals: pool.token0.decimals,
          derivedETH: pool.token0.derivedETH,
        },
        token1: {
          id: pool.token1.id,
          name: pool.token1.name,
          symbol: pool.token1.symbol,
          address: pool.token1.id,
          decimals: pool.token1.decimals,
          derivedETH: pool.token1.derivedETH,
        },
        token0Price: pool.token0Price,
        token1Price: pool.token1Price,
        volumeUSD: pool.volumeUSD,
        volumeToken0: pool.volumeToken0,
        volumeToken1: pool.volumeToken1,
        txCount: pool.totalTransactions,
        totalValueLockedToken0: pool.reserve0,
        totalValueLockedToken1: pool.reserve1,
        totalValueLockedUSD: pool.reserveUSD,
        feesUSD: new BigNumber(pool.volumeUSD).multipliedBy(0.0025).toString(),
        protocolFeesUSD: '0',
      }
    })

    const v3 = !block
      ? await clientV3.query<SectaTopPoolsLastQueryV3, SectaTopPoolsLastQueryVariablesV3>({ query: TOP_POOLS_LAST_V3 })
      : await clientV3.query<SectaTopPoolsByBlockQueryV3, SectaTopPoolsByBlockQueryVariablesV3>({
          query: TOP_POOLS_BY_BLOCK_V3,
          variables: { block: { number: block } },
        })

    const v3Formatted: PoolFields[] = v3.data?.pools.map((pool) => {
      return {
        id: pool.id,
        feeTier: pool.feeTier,
        liquidity: pool.liquidity,
        sqrtPrice: pool.sqrtPrice,
        tick: pool.tick,
        token0: {
          id: pool.token0.id,
          name: pool.token0.name,
          symbol: pool.token0.symbol,
          address: pool.token0.id,
          decimals: pool.token0.decimals,
          derivedETH: pool.token0.derivedETH,
        },
        token1: {
          id: pool.token1.id,
          name: pool.token1.name,
          symbol: pool.token1.symbol,
          address: pool.token1.id,
          decimals: pool.token1.decimals,
          derivedETH: pool.token1.derivedETH,
        },
        token0Price: pool.token0Price,
        token1Price: pool.token1Price,
        volumeUSD: pool.volumeUSD,
        volumeToken0: pool.volumeToken0,
        volumeToken1: pool.volumeToken1,
        txCount: pool.txCount,
        totalValueLockedToken0: pool.totalValueLockedToken0,
        totalValueLockedToken1: pool.totalValueLockedToken1,
        totalValueLockedUSD: pool.totalValueLockedUSD,
        feesUSD: pool.feesUSD,
        protocolFeesUSD: pool.protocolFeesUSD,
      }
    })

    const finalData: PoolDataResponse = {
      pools: v2Formatted.concat(v3Formatted),
      bundles: [
        {
          ethPriceUSD: v3.data?.bundles[0].ethPriceUSD,
        },
      ],
    }
    return finalData
  } catch (error) {
    return undefined
  }
}

export async function getTransactions() {
  try {
    const v2 = await clientV2.query<SectaLastTranscationsQuery, SectaLastTranscationsQueryVariables>({
      query: LAST_TRANSACTIONS,
    })

    const v3 = await clientV3.query<SectaLastTranscationsQueryV3, SectaLastTranscationsQueryVariablesV3>({
      query: LAST_TRANSACTIONS_V3,
    })

    const transactionsV2: Transaction[] = v2.data?.transactions.reduce((accum: Transaction[], t) => {
      const mintEntries = t.mints.map((m) => {
        return {
          type: TransactionType.MINT,
          hash: t.id,
          timestamp: t.timestamp,
          sender: m?.to,
          token0Symbol: m?.token0.symbol,
          token1Symbol: m?.token1.symbol,
          token0Address: m?.token0.id,
          token1Address: m?.token1.id,
          amountUSD: parseFloat(m?.amountUSD),
          amountToken0: parseFloat(m?.amount0),
          amountToken1: parseFloat(m?.amount1),
        }
      })
      const burnEntries = t.burns.map((m) => {
        return {
          type: TransactionType.BURN,
          hash: t.id,
          timestamp: t.timestamp,
          sender: m?.sender,
          token0Symbol: m?.token0.symbol,
          token1Symbol: m?.token1.symbol,
          token0Address: m?.token0.id,
          token1Address: m?.token1.id,
          amountUSD: parseFloat(m?.amountUSD),
          amountToken0: parseFloat(m?.amount0),
          amountToken1: parseFloat(m?.amount1),
        }
      })

      const swapEntries = t.swaps.map((m) => {
        return {
          hash: t.id,
          type: TransactionType.SWAP,
          timestamp: t.timestamp,
          sender: m?.from,
          token0Symbol: m?.token0.symbol,
          token1Symbol: m?.token1.symbol,
          token0Address: m?.token0.id,
          token1Address: m?.token1.id,
          amountUSD: parseFloat(m?.amountUSD),
          amountToken0: parseFloat(m?.amount0In !== '0' ? m?.amount0In : `-${m?.amount0Out}`),
          amountToken1: parseFloat(m?.amount1In !== '0' ? m?.amount1In : `-${m?.amount1Out}`),
        }
      })

      // eslint-disable-next-line no-param-reassign
      accum = [...accum, ...mintEntries, ...burnEntries, ...swapEntries]
      return accum
    }, [])

    const transactionsV3: Transaction[] = v3.data.transactions.reduce((accum: Transaction[], t) => {
      const mintEntries = t.mints.map((m) => {
        return {
          type: TransactionType.MINT,
          hash: t.id,
          timestamp: t.timestamp,
          sender: m?.origin,
          token0Symbol: m?.token0.symbol,
          token1Symbol: m?.token1.symbol,
          token0Address: m?.token0.id,
          token1Address: m?.token1.id,
          amountUSD: parseFloat(m?.amountUSD),
          amountToken0: parseFloat(m?.amount0),
          amountToken1: parseFloat(m?.amount1),
        }
      })
      const burnEntries = t.burns.map((m) => {
        return {
          type: TransactionType.BURN,
          hash: t.id,
          timestamp: t.timestamp,
          sender: m?.origin,
          token0Symbol: m?.token0.symbol,
          token1Symbol: m?.token1.symbol,
          token0Address: m?.token0.id,
          token1Address: m?.token1.id,
          amountUSD: parseFloat(m?.amountUSD),
          amountToken0: parseFloat(m?.amount0),
          amountToken1: parseFloat(m?.amount1),
        }
      })

      const swapEntries = t.swaps.map((m) => {
        return {
          hash: t.id,
          type: TransactionType.SWAP,
          timestamp: t.timestamp,
          sender: m?.origin,
          token0Symbol: m?.token0.symbol,
          token1Symbol: m?.token1.symbol,
          token0Address: m?.token0.id,
          token1Address: m?.token1.id,
          amountUSD: parseFloat(m?.amountUSD),
          amountToken0: parseFloat(m?.amount0),
          amountToken1: parseFloat(m?.amount1),
        }
      })
      // eslint-disable-next-line no-param-reassign
      accum = [...accum, ...mintEntries, ...burnEntries, ...swapEntries]
      return accum
    }, [])

    // Merge transactions and sort by timestamp
    const transactionsV2V3 = transactionsV2.concat(transactionsV3).sort((a, b) => {
      return parseInt(b.timestamp) - parseInt(a.timestamp)
    })

    return transactionsV2V3
  } catch (error) {
    return undefined
  }
}
